import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

const Page = styled.div`
  max-width: 1160px;

  margin: 150px auto 78px auto;
  padding: 0 40px;

  & .section-title {
    font-family: "CaslonDoric-Bold", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    font-size: 14px;
    line-height: 32px;

    letter-spacing: 0.15em;
    text-transform: uppercase;

    color: #d7490c;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
    }
  }

  @media (max-width: 767px) {
    margin: 76px 0 40px 0;
    padding: 0 20px;
  }

  & a:hover {
    color: #d7490c;
  }
`;

const Event = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & .title {
    color: #d7490c;
  }

  & h1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;
  }

  &:last-of-type .container {
    border-bottom: 0;
  }

  @media (max-width: 860px) {
    grid-template-columns: 1fr;

    &:last-of-type > div:first-of-type > .container {
      border: 0;
    }
  }

  @media (max-width: 600px) {
    &:first-of-type > div:last-of-type > .container {
      padding: 30px 0 15px 0;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;

  grid-column: ${props => props.spanDesktop};

  border-right: ${props => props.borderRightDesktop && `2px solid #000`};

  padding: ${props => props.paddingDesktop};

  @media (max-width: 1000px) {
    padding: ${props => props.paddingTablet};
  }

  @media (max-width: 860px) {
    order: ${props => props.orderMobile};

    padding: 0;
    border-right: 0;

    &:first-of-type {
      & .container {
        padding: 0 0 30px 0;
        border-bottom: 2px solid #000;
      }
    }
  }

  @media (max-width: 600px) {
    &:first-of-type {
      & .container {
        padding: 0 0 40px 0;
      }
    }
  }

  & .container {
    border-bottom: 2px solid #000;

    padding: 60px 0;

    @media (max-width: 1000px) {
      padding: 30px 0;
    }

    @media (max-width: 860px) {
      border: 0;
    }

    @media (max-width: 600px) {
      padding: 40px 0 15px 0;
    }
  }
`;

const Text = styled.div`
  font-size: 23px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.06em;

  margin: 0 auto 86px auto;

  max-width: 920px;

  & a {
    border-bottom: 2px solid #000;
  }

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;

    margin: 0 auto 40px auto;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;

    text-align: left;
  }
`;

const EventText = styled.div`
  width: 100%;

  & h2,
  & p {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.06em;

    margin: 0;

    font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;

const Events = ({ data }) => {
  const upcomingEvents = data.prismicEvents.data.upcoming_events.map(
    (content, index) => (
      <Event key={`upcoming_event_${index}`}>
        <Column
          spanDesktop={`1`}
          paddingDesktop={`0 60px 0 0`}
          paddingTablet={`0 30px 0 0`}
          borderRightDesktop={true}
          orderMobile={`2`}
        >
          <EventText className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: content.event_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content.event_location.html,
              }}
            />
            <p>
              {content.event_start_date}
              {content.event_end_date && (
                <span> — {content.event_end_date}</span>
              )}
            </p>
          </EventText>
        </Column>
        <Column
          spanDesktop={`span 2`}
          paddingDesktop={`0 0 0 60px`}
          paddingTablet={`0 0 0 30px`}
          orderMobile={`1`}
        >
          <EventText className="container">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: content.talk_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content.talk_description.html,
              }}
            />
          </EventText>
        </Column>
      </Event>
    )
  );

  const previousEvents = data.prismicEvents.data.previous_events.map(
    (content, index) => (
      <Event key={`upcoming_event_${index}`}>
        <Column
          spanDesktop={`1`}
          paddingDesktop={`0 60px 0 0`}
          paddingTablet={`0 30px 0 0`}
          borderRightDesktop={true}
          orderMobile={`2`}
        >
          <EventText className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: content.event_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content.event_location.html,
              }}
            />
            <p>
              {content.event_start_date}
              {content.event_end_date && (
                <span> — {content.event_end_date}</span>
              )}
            </p>
          </EventText>
        </Column>
        <Column
          spanDesktop={`span 2`}
          paddingDesktop={`0 0 0 60px`}
          paddingTablet={`0 0 0 30px`}
          orderMobile={`1`}
        >
          <EventText className="container">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: content.talk_title.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content.talk_description.html,
              }}
            />
          </EventText>
        </Column>
      </Event>
    )
  );

  return (
    <Page>
      <Helmet title={`Events - The Seeking State`} />
      <Text
        dangerouslySetInnerHTML={{
          __html: data.prismicEvents.data.text.html,
        }}
      />

      {upcomingEvents.length >= 1 && (
        <>
          <div className="section-title">Upcoming Events</div>
          <div className="upcoming-events">{upcomingEvents}</div>
        </>
      )}

      {previousEvents.length >= 1 && (
        <>
          <div className="section-title">Previous Events</div>
          <div className="previous-events">{previousEvents}</div>
        </>
      )}
    </Page>
  );
};

export default withPreview(Events);

export const query = graphql`
  {
    prismicEvents {
      data {
        text {
          html
        }
        upcoming_events {
          event_title {
            html
          }
          event_location {
            html
          }
          event_start_date(formatString: "DD MMMM YYYY")
          event_end_date(formatString: "DD MMMM YYYY")
          talk_title {
            html
          }
          talk_description {
            html
          }
        }
        previous_events {
          event_title {
            html
          }
          event_location {
            html
          }
          event_start_date(formatString: "DD MMMM YYYY")
          event_end_date(formatString: "DD MMMM YYYY")
          talk_title {
            html
          }
          talk_description {
            html
          }
        }
      }
    }
  }
`;
